// Common
// --------------------------------------------------------------

.rel {
  position: relative;
}

.is-hoverable:hover {
  cursor: pointer;
}

.hide {
  display: none;
}

.nowrap {
  white-space: nowrap
}

.scrollable {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}

.no-scrollable {
  overflow: hidden;
}

.no-selectable {
  user-select: none;
}

.no-clickable {
  pointer-events: none;
}

.hyphenate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.border-rounded {
  border-radius: $radius;
}


// Flexbox lib

.flex {
  display: flex;
  flex: 1 1 0;
}

.flex-rows {
  flex-direction: column;
}

.flex-cols {
  flex-direction: row;
}

.flex-w-1-3 {
  flex: 0 0 34%;
  flex-basis: 34%;
  max-width: 34%;
}

.flex-w-2-3 {
  flex: 0 0 66%;
  flex-basis: 66%;
  max-width: 66%;
}

.flex-grow {
  flex-grow: 1;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-justify {
  justify-content: space-between;
  align-items: center;
}

.flex-align-bottom {
  align-self: flex-end;
}


// Size lib

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.w50 {
  width: 50%;
}

.vw100 {
  width: 100vw;
}

.vh100 {
  height: 100vh;
}

.mlauto {
  margin-left: auto;
}

.mrauto {
  margin-right: auto;
}

.vamid {
  vertical-align: middle;
}