.chart-container {
  position: relative;
  width: 100%;
}

.chart-ratio-1-1 {
  padding-top: 100%;
}

.chart-ratio-3-2 {
  padding-top: 66%;
}

.chart-ratio-4-3 {
  padding-top: 75%;
}

.chart-ratio-1-2 {
  padding-top: 200%;
}

.chart-ratio-2-1 {
  padding-top: 50%;
}

.chart-ratio-3-1 {
  padding-top: 33%;
}

.chart {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
