// Bulma overrides
// --------------------------------------------------------------

// Buttons

.nobtn,
.nobtn[disabled] {
  background-color: transparent;
  border-color: transparent;
  font-size: 1rem;
  color: inherit;
  &:hover,
  &:focus {
    border-color: transparent;
    cursor: pointer;
  }
}


// Typography

.has-text-monospaced {
  font-family: Consolas, Lucida Console, Courier, monaco, monospace;
}

.is-size-8 {
  font-size: .675rem;
}


// Forms
// --------------------------------------------------------------

.label {
  font-weight: 600;
}

.form-narrow {
  max-width: 400px;
}


// Tables
// --------------------------------------------------------------

.table.is-borderless {
  th, td {
    border: none;
  }
}

.table.vamid {
  td, th {
    vertical-align: middle;
  }
}

.td-shrink {
  white-space: nowrap;
}

.td-expand {
  width: 99%;
}
