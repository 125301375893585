.player-card {
  border-radius: $radius;
}

.player-card-avatar {
  display: block;
  position: relative;
  max-width: 200px;
  height: auto;
  margin: 0 auto 1rem;
  border-radius: 29% 71% 58% 42% / 30% 49% 51% 70%;
}

h1.player-card-nickname {
  margin-bottom: .5rem;
}

.player-avatar-mini-container {
  display: block;
  width: 2.25rem;
  height: 2.25rem;
}
.player-avatar-mini {
  width: 2.25rem;
  max-width: 2.25rem;
  border-radius: 50%
}