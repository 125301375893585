.popup-notification {
    position: fixed;
    top: .25rem;
    right: 1rem;
    left: 1rem;
    transition: all 0.4s ease-in-out;
    z-index: 100000;
    opacity: 0;
    transform: translateY(-100px);
    text-align: center;

    @include tablet {
        right: 15%;
        left: 15%;
    }
    @include desktop {
        right: 25%;
        left: 25%;
    }

    .message-body {
        padding-right: 2.5rem;
    }
}

.popup-notification-animate {
    opacity: 1;
    transform: translateY(0);
    &:hover {
        opacity: 0.7;
    }
}

.popup-notification-close {
    position: absolute;
    top: .25rem;
    right: .125rem;
}