body {
  overflow-x: hidden;
}

.topbar,
.sidebar-topbar {
  height: $topbar-height;
  line-height: $topbar-height - .125rem;
  background-color: $primary;
}

.topbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 1rem;
  z-index: 30;

  @include desktop {
    padding-left: $sidebar-width;
  }
}

.topbar-user-image {
  width: 2.25rem;
  margin-top: -.125rem;
  border-radius: 50%;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($cobalt, .75);
  z-index: 31;
  transition: $sidebar-transition;
  opacity: 0;

  &.is-open {
    //transition: $sidebar-transition;
    opacity: 1;
  }
}

.sidebar-panel {
  position: fixed;
  top: 0;
  bottom: 0;
  width: $sidebar-width;
  background-color: $gray-lighter;
  transition: $sidebar-transition;
  transform: translateX(-100%);
  backface-visibility: hidden;
  overflow-x: hidden;
  z-index: 32;

  @include desktop {
    backface-visibility: visible;
    transform: translateX(0) translateZ(0);
  }

  &.is-open {
    backface-visibility: visible;
    transform: translateX(0) translateZ(0);
  }
}

.sidebar-logo {
  display: block;
  width: 100%;
  padding: 0 1rem;
  font-size: 1.25rem;
  color: $white;
  &:hover {
    background-color: darken($primary, 10%);
    color: $white;
  }
}

.sidebar-logo-image {
  margin: -0.25rem 1rem 0 0;
  vertical-align: middle;
}

.main-container {
  min-height: 100vh;
  padding-top: $topbar-height;
  transition: $sidebar-transition;
  background-color: $white;

  @include desktop {
    padding-left: $sidebar-width;
  }
}

.main-content {
  padding: 1rem 1rem 3rem;

  @include desktop {
    padding-left: 2rem;
  }
}

.breadcrumb-bar {
  padding: .5rem 0;
  background-color: $gray-lighter;

  @include desktop {
    padding-left: 1rem;
  }
}

.fullscreen-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.narrow-container {
  max-width: $narrow-container;
}

.box {
  display: block;
  padding: 1.25rem;
  background-color: $white;
  border-radius: $radius;
  //box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1);
}
