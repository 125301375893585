// Variables
// --------------------------------------------------------------

// Custom colors
$cobalt:                            rgba(38, 53, 68, 255);
$custom-colors:                     ('cobalt': ($cobalt, $white));

// General
$body-font-size:                   .875rem;
//$text:                              #f00; // $cobalt;
//$body-color:                        #f00; // $cobalt;
$link:                              $primary;
$link-focus-border:                 $primary;
$input-shadow:                      0;

// Bulma overrides
$green:                             $brand-success;
$yellow:                            $brand-warning;
$red:                               $brand-danger;


// Custom 'narrow' responsive breakpoint
$narrow-breakpoint:                 400px;

// Fontawesome path
//$fa-font-path:              '../../node_modules/@fortawesome/fontawesome-free-webfonts/webfonts';

// Layout
$topbar-height:                     3.25rem;
$sidebar-width:                     17.5rem;
$sidebar-transition:                all .3s ease-in-out;
//$menu-item-color:                   lighten($cobalt, 45%);
//$menu-item-hover-color:             lighten($cobalt, 45%);
//$menu-item-hover-background-color:  darken($cobalt, 5%);
//$menu-label-color:                  lighten($cobalt, 35%);
$menu-item-hover-background-color:  $white;

$narrow-container:                   31rem;

// Bulma inputs and buttons like pre v0.8.0
$control-border-width:              1px;
$button-border-width:               $control-border-width;
$control-height:                    2.25em;
$control-padding-vertical:          calc(0.375em - #{$control-border-width});
$control-padding-horizontal:        calc(0.625em - #{$control-border-width});
$button-padding-vertical:           calc(0.375em - #{$button-border-width});
$button-padding-horizontal:         0.75em;
