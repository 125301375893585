// Colors
// --------------------------------------------------------------

$black:             #000000;
$white:             #FFFFFF;
$gray-darker:       #2B2B2B;
$gray-dark:         #747474;
$gray:              #A8A8A8;
$gray-light:        #E0E0E0;
$gray-lighter:      #F8F8F8;
$primary-dark:      #175899;
$primary:           #7ab317;
$primary-light:     #7CBDFF;
$primary-lighter:   #CBEFFF;
$brand-success:     #66BB6A;
$brand-info:        #81D4FA;
$brand-warning:     #FBC02D;
$brand-danger:      #FB5A3F;
$red-lighter:       lighten($brand-danger, 20%);
$warning-lighter:   lighten($brand-warning, 30%);
