.games-table-cappotto-row {
  //background-color: $gray-lighter;
}

.games-table-date {
  color: $body-color;
}

.games-table-player {
  //
}

.games-table-positions {
  font-size: .675rem;
  line-height: 2.25;
  color: $gray-light;
}

.games-table-score {
  width: 5rem;
  margin: 0 auto;
}

.games-table-winner {
  position: absolute;
  top: 50%;
  display: block;
  width: .5rem;
  height: .5rem;
  margin-top: -.125rem;
  border-radius: 50%;
  &.games-table-winner-left {
    left: .25rem;
  }
  &.games-table-winner-right {
    right: .25rem;
  }
}

.new-game-table {
  vertical-align: middle;

  @include touch {
    margin-left: auto;
    margin-right: auto;
  }
}

.new-game-score {
  @include touch {
    margin-left: auto;
    margin-right: auto;
  }
}

.new-game-score-points {
  position: relative;
  border-top: 5px solid $gray-dark;
  border-bottom: 5px solid $gray-dark;
  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    border-left: 10px solid $gray-dark;
    background-color: $gray-dark;
    height: 100%;
    transform: translateX(-50%);
  }
}

.new-game-selecting {
  padding-bottom: 9rem;

  @include desktop {
    padding-bottom: 2rem;
  }
}

.new-game-selected-players {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 9rem;
  padding: .25rem .5rem .5rem;
  background-color: $body-background-color;
  border-top: $table-cell-border;
  z-index: 33;

  @include desktop {
    position: relative;
    max-width: $narrow-container;
  }
}

.new-game-continue-button {
  margin-top: -.5rem;
}
